import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import { useDispatch, useSelector } from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    clear_analysis,
    createAnalysis,
    CREATE_ANALYSIS
} from "../../../redux/analysis";
import {getAllAnalysisList} from "../../../redux/analysis_list";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/footer";

const CreateAnalysis = (props) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const _create_analysis = useSelector((state) => state.analysis_module._create_analysis);
    const { token } = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({
        codigo: "",
        id_lista_analisis: id,
        intento_minimo: 0,
        nombre: "",
        nombre_variable: "",
        reglas: [],
        tipo_variable: ""

    });

    useEffect(() => {
        if (_create_analysis.status === 201) {
            dispatch(clear_analysis(CREATE_ANALYSIS));
            history.push(`/lista-analisis/editar/${id}`);
            dispatch(getAllAnalysisList(token));
        } else if (_create_analysis.status !== 0) {
            dispatch(clear_analysis(CREATE_ANALYSIS));
            // setMsj(_create_analysis.message);
            toast.error(_create_analysis.message, {position: toast.POSITION.TOP_RIGHT});

        }
    }, [_create_analysis, dispatch, history, token, id]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const addRuler = () => {
        let reglas = form.reglas;
        let cant = reglas.length
        let num = 1
        if (cant > 0) {
            num =  reglas[cant - 1].num + 1
        }
        reglas.push({
            num: num,
            codigo: "",
            limite_inferior: 0,
            limite_superior: 0,
            puntos: 0
        })
        setForm({
            ...form,
            reglas: reglas
        })
    }

    const deleteRuler = (num) => {
        let reglas = form.reglas;
        // eslint-disable-next-line
        reglas.map((item) => {
            setForm({
                reglas: reglas.filter(item => item.num !== num)
            })
        })
    }

    const handleRuler = (x, event) => {

        let reglas = form.reglas;
        let obj = form.reglas[x]

        let key = event.currentTarget.name;
        let value = event.currentTarget.value;

        if(key === 'limite_inferior' || key === 'limite_superior' || key === 'puntos'){
            value = value.split('.').length===2?value:value+".00";
            value = parseFloat(value)
            value += 0.00
        }
        obj = {
            ...obj,
            [key]: value
        }
        reglas[x] = obj;
        setForm({
            ...form,
            reglas: reglas
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields
        if (form.nombre === "") {
            toast.error("El campo nombre es obligatorio", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.codigo === "") {
            toast.error("El campo código es obligatorio", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.nombre_variable === "") {
            toast.error("El campo nombre de variable es obligatorio", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.tipo_variable === "") {
            toast.error("El campo tipo de variable es obligatorio", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.intento_minimo === 0) {
            toast.error("El campo intento mínimo es obligatorio", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.reglas.length === 0) {
            toast.error("Debe ingresar por lo menos una regla", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        dispatch(createAnalysis(token, form));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Análisis"
                            items={[
                                { label: "Lista de análisis", link: "/lista-analisis" },
                                { label: "Editar lista de análisis", link: `/lista-analisis/editar/${id}` },
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear análisis</h4>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer /></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                label="Nombre"
                                                name="nombre"
                                                value={form.nombre}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                label="Código"
                                                name="codigo"
                                                value={form.codigo}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Nombre de variable"
                                                name="nombre_variable"
                                                value={form.nombre_variable}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Tipo de variable"
                                                name="tipo_variable"
                                                value={form.tipo_variable}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Intento mínimo"
                                                name="intento_minimo"
                                                value={form.intento_minimo}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <h5 className="text-bold-700">Reglas del análisis</h5>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end" >
                                            <button type="button" className="btn btn-lg btn-primary" value={"Agregar"} onClick={addRuler}>
                                                Agregar
                                            </button>
                                        </div>

                                    </div>
                                    <ul className="list-group mt-2">
                                        {
                                            form.reglas.map((_, e) => (
                                                <li className="container-fluid list-group-item" >
                                                    <div className="form-group row">
                                                        <div className="col-md-1 col-sm-2">
                                                            <label className="col-form-label">Codigo</label>
                                                        </div>
                                                        <div className="col-md-2 col-sm-4">
                                                            <input type="text" id="last-name" className="form-control" name="codigo" value={_.codigo} onChange={(event) => handleRuler(e, event)} />
                                                        </div>
                                                        <div className="col-md-1 col-sm-2">
                                                            <label className="col-form-label">Límite inferior</label>
                                                        </div>
                                                        <div className="col-md-1 col-sm-4">
                                                            <input type="number" id="last-name" className="form-control"   step="0.25"  name="limite_inferior" value={_.limite_inferior} onChange={(event) => handleRuler(e, event)} />
                                                        </div>
                                                        <div className="col-md-1 col-sm-2">
                                                            <label className="col-form-label">Límite superior</label>
                                                        </div>
                                                        <div className="col-md-1 col-sm-4">
                                                            <input type="number" id="last-name" className="form-control"   step="0.25" name="limite_superior" value={_.limite_superior}  onChange={(event) => handleRuler(e, event)} />
                                                        </div>
                                                        <div className="col-md-1 col-sm-2">
                                                            <label className="col-form-label">Puntos</label>
                                                        </div>
                                                        <div className="col-md-2 col-sm-4">
                                                            <input type="number" id="last-name" className="form-control" step="0.25" name="puntos" value={_.puntos}  onChange={(event) => handleRuler(e, event)} />
                                                        </div>
                                                        <div className="col-md-2 col-sm-12 text-center">
                                                            <button type="button" className="btn btn-icon rounded-circle btn-light-danger mr-1 mb-1" onClick={() => deleteRuler(_.num)}>
                                                                <i className="bx bx-trash"/></button>
                                                        </div>

                                                    </div>
                                                </li>

                                            ))
                                        }

                                    </ul>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to={`/lista-analisis/editar/${id}`} className="btn btn-outline-dark m-1">
                                        Atrás
                                    </Link>
                                    <Button type="submit" text="Guardar" theme="primary" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default CreateAnalysis;
