import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from "react-router-dom";
import Input from '../../components/input';
import Button from '../../components/button';
import {
    reset_password, clear_user_module,
    RESET_PASSWORD
} from '../../redux/users'
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const RequestResetPassword = () => {
    let dispatch = useDispatch();

    const _reset_password = useSelector((state) => state.users_module._reset_password);
    const [form, setForm] = useState({
        email: '',
        url: window.location.origin + "/confirmar-contrasena/",
    })
    useEffect(() => {
        if (_reset_password.status === 200) {
            dispatch(clear_user_module(RESET_PASSWORD));
            toast.success('Se ha enviado un correo con las instrucciones para recuperar la contraseña', {position: toast.POSITION.TOP_RIGHT});
        } else if (_reset_password.status !== 0) {
            dispatch(clear_user_module(RESET_PASSWORD));
            toast.error('No se ha podido enviar el correo de recuperación de contraseña', {position: toast.POSITION.TOP_RIGHT});
        }

    }, [_reset_password, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (form.email === '') {
            toast.error('Debe ingresar el correo electrónico', {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        dispatch(reset_password(form));
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <div className="content-wrapper">
                <div className="content-header row">
                </div>
                <div className="content-body">
                    <section className="row flexbox-container">
                        <div className="col-xl-7 col-10">
                            <div className="card bg-authentication mb-0">
                                <div className="row m-0">
                                    {/* left section-login */}
                                    <div className="col-md-6 col-12 px-0">
                                        <div
                                            className="card disable-rounded-right d-flex justify-content-center mb-0 p-2 h-100">
                                            <div className="card-header pb-1">
                                                <div className="card-title">
                                                    <h4 className="text-center mb-2">¿Olvidó su contraseña?</h4>
                                                </div>
                                            </div>
                                            <div
                                                className="form-group d-flex justify-content-between align-items-center mb-2">
                                                <div className="text-left">
                                                    <div className="ml-3 ml-md-2 mr-1">
                                                        <Link
                                                            to="/"
                                                            className="card-link btn btn-outline-dark text-nowrap"
                                                        >
                                                            Iniciar Sesión
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-content">
                                                <div className="card-body">
                                                    <div><ToastContainer/></div>
                                                    <form className="mb-2" onSubmit={handleSubmit}>
                                                        <Input
                                                            label="Correo electrónico"
                                                            type="email"
                                                            name="email"
                                                            placeholder="Correo electrónico"
                                                            value={form.email}
                                                            onChange={handleChange}
                                                        />
                                                        <Button
                                                            type="submit"
                                                            classes="btn-primary  btn-block"
                                                            text={<>
                                                                Enviar
                                                                <i id="icon-arrow" className="bx bx-right-arrow-alt"/>
                                                            </>}
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* right section image */}
                                    <div className="col-md-6 d-md-block d-none text-center align-self-center p-3">
                                        <img className="img-fluid" src="base/app-assets/images/pages/reset-password.png"
                                             alt="branding logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* reset password ends */}
                </div>
            </div>
        </div>
    );
}

export default RequestResetPassword;
