import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
    createPriceFixed,
    CREATE_PRICE_FIXED,
    clear_prices_fixed,
    getAllPricesFixed
} from "../../../redux/prices_fixed";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SelectInput from "../../../components/select";
import Footer from "../../../components/footer";

const CreatePriceFixed = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const _create_price_fixed = useSelector((state) => state.prices_fixed_module._create_price_fixed);
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_providers = useSelector(
        (state) => state.provider_module._get_all_providers
    );

    const [provider, setProvider] = useState([]);

    useEffect(() => {
        if (_get_all_providers.data) {
            setProvider(_get_all_providers.data.datos)
        }

    }, [_get_all_providers]);

    const [form, setForm] = useState({
        id_proveedor: 0,
        peso_fijado: 0,
        precio_fijado: 0,
    });
    useEffect(() => {
        if (_create_price_fixed.status === 201) {
            dispatch(clear_prices_fixed(CREATE_PRICE_FIXED));
            history.push("/fijacion");
        } else if (_create_price_fixed.status !== 0) {
            dispatch(clear_prices_fixed(CREATE_PRICE_FIXED));
            toast.error(_create_price_fixed.message, {position: toast.POSITION.TOP_RIGHT});
        }
        dispatch(getAllPricesFixed(token));
    }, [_create_price_fixed, dispatch, history, token]);

    useEffect(() => {
    }, []);

    const handleChange = (e) => {
        if (e.target.name === "id_proveedor") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields
        if (form.precio_fijado === 0) {
            toast.error("Debe ingresar el precio fijado", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.peso_fijado === 0) {
            toast.error("Debe ingresar el peso fijado", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.id_proveedor === 0) {
            toast.error("Debe seleccionar el proveedor", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        dispatch(createPriceFixed(token, form));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Fijación"
                            items={[
                                {label: "Lista de fijación", link: "/fijacion"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear fijación</h4>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer /></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                name="precio_fijado"
                                                label="Precio fijado"
                                                value={form.precio_fijado}
                                                onChange={handleChange}
                                                type="number"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                name="peso_fijado"
                                                label="Peso fijado"
                                                value={form.peso_fijado}
                                                onChange={handleChange}
                                                type="number"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Proveedor</label>
                                                <SelectInput
                                                    label="provider"
                                                    name="id_proveedor"
                                                    value={form.id_proveedor}
                                                    onChange={handleChange}
                                                    options={provider ? provider.map((value) => ({
                                                        name: '[' + value.ruc + '] ' +value.nombres + ' ' + value.apellidos,
                                                        id: value.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/proveedores" className="btn btn-outline-dark m-1">
                                        Atrás
                                    </Link>
                                    <Button
                                        type="submit"
                                        text="Guardar"
                                        theme="primary"
                                        classes="m-1"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default CreatePriceFixed;
