import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    updateSetting,
    clear_setting,
    SETTING_UPDATE,
    getAllSetting
} from "../../../redux/setting";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/footer";

const UpdateSetting = () => {
    let history = useHistory();
    /* Getting the id from the url. */
    const {id} = useParams();

    const dispatch = useDispatch();
    /* Getting the token from the redux store. */
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    /* Getting the data from the redux store. */
    const _get_all_setting = useSelector(
        (state) => state.setting_module._get_all_setting
    );

    const _update_setting = useSelector(
        (state) => state.setting_module._update_setting
    );
    /* A state variable that is used to store the data of the form. */
    const [form, setForm] = useState({
        id: id,
        precio_quintal: 0,
        limite_ajuste_inferior: 0,
        limite_ajuste_superior: 0,
    });

    /* A hook that is used to execute a function when the component is rendered. */
    useEffect(() => {
        if (_get_all_setting.data) {
            let item = _get_all_setting.data.datos.filter((x) => x.id === parseInt(id))
            if (item.length > 0) {
                setForm(item[0]);
            }
        }
    }, [_get_all_setting, id]);

    useEffect(() => {
        if (_update_setting.status === 200) {
            dispatch(clear_setting(SETTING_UPDATE));
            history.push("/configuraciones");
            dispatch(getAllSetting(token));
        } else if (_update_setting.status !== 0) {
            dispatch(clear_setting(SETTING_UPDATE));
            toast.error(_update_setting.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_update_setting, dispatch, history, token]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate data
        if (form.precio_quintal === 0) {
            toast.error("Debe ingresar el precio por quintal", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.limite_ajuste_inferior === 0) {
            toast.error("Debe ingresar el límite inferior", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.limite_ajuste_superior === 0) {
            toast.error("Debe ingresar el límite superior", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        dispatch(updateSetting(token, id, form));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Configuración"
                            items={[
                                {label: "Lista de configuraciones", link: "/configuraciones"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar configuración</h4>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer /></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                name="precio_quintal"
                                                label="Precio por quintal"
                                                value={form.precio_quintal}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className=" col-md-6">
                                            <Input
                                                name="limite_ajuste_inferior"
                                                label="Límite inferior"
                                                value={form.limite_ajuste_inferior}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className=" col-md-6">
                                            <Input
                                                name="limite_ajuste_superior"
                                                label="Límite superior"
                                                value={form.limite_ajuste_superior}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/configuraciones" className="btn btn-outline-dark m-1">
                                        Atrás
                                    </Link>
                                    <Button
                                        type="submit"
                                        text="Guardar"
                                        theme="primary"
                                        classes="m-1"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default UpdateSetting;
