import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Button from "../../../components/button";
import Footer from "../../../components/footer";
import {
    clear_analysis_list,
    CREATE_ANALYSIS_LIST,
    createAnalysisList,
    getAllAnalysisList
} from "../../../redux/analysis_list";
import Input from "../../../components/input";
import SelectInput from "../../../components/select";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const AnalysisList = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const analysis_list = useSelector(
        (state) => state.analysis_list_module._get_all_analysis_list
    );

    const [data, setData] = useState([]);

    useEffect(() => {
        if (analysis_list.data.datos) {
            setData(analysis_list.data.datos);
        }
    }, [analysis_list]);

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);


    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);


    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_analysislist').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        history.push(`/lista-analisis/editar/${item.id}`);
                    },
                })

            }
        }
        setActions(act);
    }, [permisos, history]);

    const _get_all_beans_types = useSelector(
        (state) => state.beans_types_module._get_all_beans_types
    );

    const [beans_types, setBeansTypes] = useState([]);

    useEffect(() => {
        if (_get_all_beans_types.data) {
            setBeansTypes(_get_all_beans_types.data.datos)
        }

    }, [_get_all_beans_types]);

    const [showOverlay, setShowOverlay] = useState(false);
    const [showSibarRight, setShowSibarRight] = useState(false);

    const initForm = {
        nombre: "",
        codigo: "",
        id_tipo_grano: '',
    }

    const [form, setForm] = useState(initForm);

    const _create_analysis_list = useSelector((state) => state.analysis_list_module._create_analysis_list);

    useEffect(() => {
        if (_create_analysis_list.status === 201) {
            dispatch(clear_analysis_list(CREATE_ANALYSIS_LIST));
            setForm({
                nombre: "",
                codigo: "",
                id_tipo_grano: '',
            });
            setShowOverlay(false);
            setShowSibarRight(false);
            dispatch(getAllAnalysisList(token));
            let id = _create_analysis_list.data.id
            history.push(`/lista-analisis/editar/${id}`);
        } else if (_create_analysis_list.status !== 0) {
            dispatch(clear_analysis_list(CREATE_ANALYSIS_LIST));
            toast.error(_create_analysis_list.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_create_analysis_list, dispatch, history, token]);


    const handleChange = (e) => {
        if (e.target.name === "id_tipo_grano") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.codigo === "") {
            toast.error("Debe ingresar el código", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.id_tipo_grano === "") {
            toast.error("Debe seleccionar el tipo de grano", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        dispatch(createAnalysisList(token, form));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Análisis"
                            items={[{label: "Lista de análisis", link: "/lista-analisis"}]}
                        />
                        {
                            permisos.filter(element => element.codigo === "add_analysislist").length > 0 ?
                                <button
                                    className="mt-2 btn btn-primary"
                                    onClick={() => {
                                        setShowSibarRight(!showSibarRight);
                                        setShowOverlay(!showSibarRight);
                                    }}
                                >Nueva Lista</button>
                                : null
                        }

                    </div>
                </div>
                <div className="content-body">
                    <div><ToastContainer/></div>
                    <div className="content-area-wrapper" style={{margin: 0}}>
                        <div className={`sidebar-left ${showSibarRight ? 'show' : ''}`}>
                            <div className="sidebar">
                                {

                                    permisos.filter(element => element.codigo === "add_analysislist").length > 0 ?
                                        <div className={`compose-new-mail-sidebar ${showSibarRight ? 'show' : ''}`}
                                             style={{
                                                 overflowY: 'scroll',
                                             }}
                                        >
                                            <div className="card shadow-none quill-wrapper p-0">
                                                <div className="card-header">
                                                    <h3 className="card-title" id="visita">
                                                        Nueva Lista de Análisis
                                                    </h3>
                                                    <button type="button" className="close close-icon"
                                                            onClick={() => {
                                                                setForm(initForm);
                                                                setShowSibarRight(false);
                                                                setShowOverlay(false);
                                                            }}
                                                    >
                                                        <i className="bx bx-x"/>
                                                    </button>
                                                </div>
                                                <form onSubmit={handleSubmit} id="compose-form">
                                                    <div className="card-content">
                                                        <div className="card-body pt-0">
                                                            <Input
                                                                label="nombre"
                                                                name="nombre"
                                                                value={form.nombre}
                                                                onChange={handleChange}
                                                            />
                                                            <Input
                                                                label="codigo"
                                                                name="codigo"
                                                                value={form.codigo}
                                                                onChange={handleChange}
                                                            />
                                                            <div className="form-group">
                                                                <label>Tipo de grano</label>
                                                                <SelectInput
                                                                    label="beans_type"
                                                                    name="id_tipo_grano"
                                                                    value={form.id_tipo_grano}
                                                                    onChange={handleChange}
                                                                    options={beans_types ? beans_types.map((value) => ({
                                                                        name: value.nombre,
                                                                        id: value.id,
                                                                    })) : []}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer d-flex justify-content-end pt-0">
                                                        <Button
                                                            text="Guardar"
                                                            theme="primary"
                                                            type="submit"
                                                        />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <div className={`app-content-overlay ${showOverlay ? 'show' : ''}`}
                             onClick={
                                 () => {
                                     setForm(initForm);
                                     setShowSibarRight(!showSibarRight);
                                     setShowOverlay(!showOverlay);
                                 }
                             }
                        />
                        <div className="card">
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <DataTable
                                            dataTable={data}
                                            columns={[
                                                {
                                                    name: "nombre",
                                                    label: "Nombre de lista",
                                                    type: "text",
                                                },
                                                {
                                                    name: "fecha_creacion",
                                                    label: "Fecha creación",
                                                    type: "text",
                                                },
                                                {
                                                    name: "tipo_grano",
                                                    label: "Tipo de grano",
                                                    type: "object",
                                                    field_show: "nombre"
                                                },
                                                {
                                                    name: "usuario_crea",
                                                    label: "Usuario crea",
                                                    type: "text",
                                                },
                                            ]}
                                            actions={actions}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default AnalysisList;
