import React, {useEffect, useState} from "react";
import {URLAPI} from "../config/index";

export const add_datable_link = () => {
    document.head.innerHTML += `
    <link rel="stylesheet" type="text/css" href="/base/app-assets/vendors/css/tables/datatable/datatables.min.css">
    `;
    document.body.innerHTML += `
    <script src="/base/app-assets/vendors/js/tables/datatable/datatables.min.js"></script>
    
    `;
    //add script for datatable
    document.body.innerHTML += `
    <script>
    $(document).ready(function() {
        $('#table').DataTable();
    } );
    </script>
    `;
};
/** @module Components/Datatable */
/**
 * Una función que devuelve una tabla con paginación, búsqueda y acciones.
 * @param {array} dataTable - Array de datos para la tabla.
 * @param {ino} dataLimit - Límite de datos por página.
 * @param {int} pageLimit - Número de páginas.
 * @param {array} columns - Array de columnas de la tabla.
 * @param {array} actions - Array de acciones de la tabla.
 * @return {object} Un componente que representa una tabla.
 * @example
 * <DataTable
 *      dataTable={[
 *                  {id:1,name:"Juan",lastname:"Perez",age:30},
 *                  {id:2,name:"Juan",lastname:"Perez",age:30}
 *                ]}
 *      dataLimit={10}
 *      pageLimit={5}
 *      columns={[
 *                {label:"ID",name:"id"},
 *                {label:"Nombre",name:"name"},
 *                {label:"Apellido",name:"lastname"},
 *                {label:"Edad",name:"age"}
 *             ]}
 *      actions={[
 *                  {label:"Editar",name:"edit",icon:"bx bx-edit",color:"primary",onClick:()=>{}},
 *                  {label:"Eliminar",name:"delete",icon:"bx bx-trash",color:"danger",onClick:()=>{}}
 *              ]}
 *      />
 */
const DataTableSimple = ({
                     dataTable = [{}],
                     dataLimit = 0,
                     columns = [{name: "", label: "", type: "", field_show: ""}],
                   }) => {
    const [data_table, setDataTable] = useState([]);
    // eslint-disable-next-line
    const [currentPage, setCurrentPage] = useState(1);


    const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return data_table.slice(startIndex, endIndex);
    };


    useEffect(() => {
        setDataTable(dataTable);
        //converto to array
        if (typeof dataTable === "object") {
            setDataTable(Object.values(dataTable));
        }
    }, [dataTable]);

    return (
        <table className="table table-striped table-bordered" id="table">
            <thead>
            <tr className="text-center">
                {/*columns with sortable*/}
                {columns.map((column) => (
                    <th key={column.name}>{column.label}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {getPaginatedData().map((item, index) => (
                <tr key={index}>
                    {columns.map((column, index) => (
                        <td key={index}>
                            {column.type === "date" ? (
                                new Date(item[column.name]).toLocaleDateString()
                            ) : column.type === "boolean" ? (
                                item[column.name] ? (
                                    "Si"
                                ) : (
                                    "No"
                                )
                            ) : column.type === "avatar-dev" ? (
                                <img
                                    src={item[column.name]
                                        .replace("/media/", "")
                                        .replace("https%253A", "https:/")
                                        .replace("http%3A", "http:/")}
                                    alt="avatar"
                                    width="50"
                                    height="50"
                                />
                            ) : column.type === "avatar-array" ? (
                                item[column.name].map((it) => (
                                    <img
                                        src={URLAPI + it.url}
                                        alt="avatar"
                                        width="50"
                                        height="50"
                                        style={{padding: "1px"}}
                                    />
                                ))
                            ) : column.type === "avatar" ? (
                                item[column.name] ?
                                    <img
                                        src={URLAPI + item[column.name]}
                                        alt="avatar"
                                        width="50"
                                        height="50"
                                    /> :
                                    <img
                                        src={window.location.origin + "/base/app-assets/images/profile/no_perfil.png"}
                                        alt="avatar"
                                        width="50"
                                        height="50"
                                    />
                            ) : column.type === "array" ? (
                                //column.field_show recorrer el array y mostrar el valor
                                item[column.name].map((it, index) => (
                                    <>
                                        <span
                                            key={index}
                                            className="
                                        badge badge-success badge-pill mt-1
                                      "
                                        >
                                          {it[column.field_show]}{" "}
                                        </span>
                                        <br/>
                                    </>
                                ))
                            ) : column.type === "object" ? (
                                <>
                                    {item[column.name][column.field_show]}{" "}
                                </>
                            ) : (
                                item[column.name]
                            )}
                        </td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default DataTableSimple;
