import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {EN_PROCESO, PENDIENTE_DE_PAGO} from "../../../config"
import Modal from "../../../components/modal";
import {
    clear_batches,
    payVehicle,
    getAllBatches,
    confirmVehicle,
    PAY_VEHICLE,
    VEHICLE_CONFIRM,
} from "../../../redux/batches";
import {toast} from "react-toastify";
import DataTable from "../../../components/data-table-simple";
import Footer from "../../../components/footer";

const DetailVehicle = (props) => {
    const {id_lote} = useParams();
    const {id_vehiculo} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_batches = useSelector(
        (state) => state.batches_module._get_all_batches
    );

    const _pay_vehicle = useSelector(
        (state) => state.batches_module._pay_vehicle
    );

    const _confirm_vehicle = useSelector(
        (state) => state.batches_module._confirm_vehicle
    );

    useEffect(() => {
        if (_pay_vehicle.status === 200) {
            dispatch(clear_batches(PAY_VEHICLE));
            history.push(`/lote/detalle/${id_lote}`);
            dispatch(getAllBatches(token));
        } else if (_pay_vehicle.status !== 0) {
            dispatch(clear_batches(PAY_VEHICLE));
            toast.error(_pay_vehicle.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_pay_vehicle, dispatch, history, token, id_lote]);

    useEffect(() => {
        if (_confirm_vehicle.status === 200) {
            dispatch(clear_batches(VEHICLE_CONFIRM));
            history.push(`/lote/detalle/${id_lote}`);
            dispatch(getAllBatches(token));
        } else if (_confirm_vehicle.status !== 0) {
            dispatch(clear_batches(VEHICLE_CONFIRM));
            toast.error(_confirm_vehicle.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_confirm_vehicle, dispatch, history, token, id_lote]);

    const [form, setForm] = useState({
        cantidad_vehiculo: 0,
        fecha_registro: "",
        id: 0,
        precio_fijado: {
            codigo: "",
            id: 0,
            peso_disponible: "",
            peso_fijado: 0,
            precio_fijado: "",
        },
        proveedor: {
            apellidos: "",
            id: 0,
            nombres: "",
            ruc: 0,
        },
        total_peso_bruto: 0,
        total_peso_neto: 0,
        total_peso_tara: 0,
        usuario_analiza: "",
        usuario_registra: "",
        vehiculos: [],
        moneda: "",
        simbolo: "",
    });

    const [vehicle, setVehicle] = useState({
        analisis_vehiculo: [],
        colorestado: "",
        estado: "",
        fecha_registro: "",
        id: 0,
        peso_bruto: "",
        peso_entrada: "",
        peso_neto: "",
        peso_salida: "",
        peso_tara: "",
        placa: "",
        precio_quintal: 0,
        precio_total_neto: 0,
        puntuacion_final: 0,
        romaneo: false,
        tipo_grano: "",
        observacion: "",
        usuario_analiza: "",
        usuario_registra: "",
        usuario_paga: "",
    });

    const [analisis, setAnalisis] = useState({
            id: "",
            puntuacion_sistema: 0,
            ajuste_usuario: "",
            puntuacion_final: 0,
            analisis: {
                id: "",
                codigo: "",
                nombre: "",
                nombre_variable: "",
                tipo_variable: "",
                intento_minimo: 0
            },
            detalles: [],
            regla: {
                codigo: "",
                limite_inferior: "",
                limite_superior: "",
                puntos: 0
            },
            cantidad_detalle: 0
    });

    const [showModal, setShowModal] = useState(false);
    const [totalActual, setTotalActual] = useState(0);
    const [totalFijado, setTotalFijado] = useState(0);
    const [priceFixed, setPriceFixed] = useState(false);

    useEffect(() => {
        if (_get_all_batches.data) {
            let value = _get_all_batches.data.datos.filter((x) => x.id === parseInt(id_lote))
            if (value.length > 0) {
                setForm(value[0]);
                let vehicle = value[0].vehiculos.filter((x) => x.id === parseInt(id_vehiculo))
                if (vehicle.length > 0) {
                    setVehicle(vehicle[0])
                }
                if (value[0].precio_fijado) {
                    setPriceFixed(true);
                }
            }
        }
    }, [_get_all_batches, id_lote, id_vehiculo]);

    const ButtonSend = () => {
        if (vehicle.estado === EN_PROCESO && parseFloat(vehicle.peso_bruto) !== 0
            && parseFloat(vehicle.peso_tara) !== 0 && parseFloat(vehicle.peso_salida) !== 0) {
            return (
                <>
                    <button
                        type="submit"
                        className="btn btn-success m-2"
                        onClick={() => {
                            if (priceFixed && parseFloat(form.precio_fijado.peso_disponible) > 0  && parseFloat(form.precio_fijado.peso_disponible) < parseFloat(vehicle.peso_neto)) {
                                let peso = parseFloat(vehicle.peso_neto) - (parseFloat(vehicle.peso_neto) * (vehicle.puntuacion_final / 100))
                                let subtotal = form.precio_fijado.peso_disponible * form.precio_fijado.precio_fijado
                                let result = peso - parseFloat(form.precio_fijado.peso_disponible)
                                let _total = (result * form.precio_quintal) + subtotal
                                setTotalFijado(_total)
                                setTotalActual(peso * form.precio_quintal)
                                setShowModal(true)
                            } else {
                                let types = 0
                                if (priceFixed && parseFloat(form.precio_fijado.peso_disponible) > parseFloat(vehicle.peso_neto)) {
                                    types = 1
                                }
                                vehicleConfirm(types)
                            }
                        }}
                    >
                        Enviar a pago
                    </button>
                </>
            );
        }
        if (vehicle.estado === PENDIENTE_DE_PAGO) {
            return (
                <>
                    <button
                        type="submit"
                        className="btn btn-success m-2"
                        onClick={sendVehiclePay}
                    >
                        Pagar
                    </button>
                </>
            );
        }
    };

    const vehicleConfirm = (type) => {
        dispatch(confirmVehicle(token, {id_vehiculo: id_vehiculo, tipo: type}));
    };

    const sendVehiclePay = () => {
        dispatch(payVehicle(token, {id_vehiculo: id_vehiculo}));
        setShowModal(false);
    }

    const [modalDetail, setModalDetail] = useState(false);

    function detail_vehicle(id) {
        if (vehicle.analisis_vehiculo.length > 0) {
            let analysis_vehicle = vehicle.analisis_vehiculo.filter((x) => x.id === parseInt(id))
            if (analysis_vehicle.length > 0) {
                setAnalisis(analysis_vehicle[0])
                setModalDetail(true);
            }
        }
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Lotes"
                            items={[
                                {label: "Lotes", link: "/lotes"},
                                {label: "Detalle de lote", link: `/lote/detalle/${id_lote}`},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-8 col-sm-6 mt-1">
                                        <h6 className='text-bold-500'>
                                            {form.proveedor.nombres + " " + form.proveedor.apellidos}
                                        </h6>
                                    </div>
                                    <div className="col-md-4 col-sm-6 mb-sm-2 mb-1 mt-1 text-sm-right">
                                        <h6 className="text-bold-700" style={{color: vehicle.colorestado}}>
                                            {vehicle.estado}
                                        </h6>
                                    </div>
                                    <div className="col-md-4 mb-1">
                                        {`Peso bruto: ${vehicle.peso_bruto} ${form.simbolo}`}
                                    </div>
                                    <div className="col-md-4 col-sm-6 mb-1">
                                        {`Peso tara: ${vehicle.peso_tara} ${form.simbolo}`}
                                    </div>
                                    <div className="col-md-4 col-sm-6 mb-1">
                                        {`Peso neto: ${vehicle.peso_neto} ${form.simbolo}`}
                                    </div>
                                    <div className="col-md-4 col-sm-6 mb-1">
                                        {`Precio quintal:  ${form.moneda} ${vehicle.precio_quintal}`}
                                    </div>
                                    <div className="col-md-8 col-sm-6 mb-1">
                                        {`Peso fijado: ${form.precio_fijado ? form.precio_fijado.peso_fijado : 0} ${form.simbolo}`}
                                    </div>
                                    {/*<div className="col-md-4 col-sm-6 mb-1"/>*/}
                                    <div className="col-md-4 col-sm-6 mb-1">
                                        {`Registrado por: ${vehicle.usuario_registra}`}
                                    </div>
                                    <div className="col-md-4 col-sm-6 mb-1">
                                        {`Revisado por: ${vehicle.usuario_analiza}`}
                                    </div>
                                    <div className="col-md-4 col-sm-6 mb-1">
                                        {`Pagado por: ${vehicle.usuario_paga}`}
                                    </div>
                                    <div className="col-md-12 col-sm-12 mb-1">
                                        {vehicle.observacion !== "" && `Observación: ${vehicle.observacion}`}
                                    </div>
                                    <div className="col-md-4 col-sm-6 mb-1" style={{fontWeight: 'bold'}}>
                                        {`Peso de entrada: ${vehicle.peso_entrada} ${form.simbolo}`}
                                    </div>
                                    <div className="col-md-8 col-sm-6 mb-1" style={{fontWeight: 'bold'}}>
                                        {`Peso salida: ${vehicle.peso_salida} ${form.simbolo}`}
                                    </div>
                                    {/*<div className="col-md-4 col-sm-6 mb-1"/>*/}
                                    <div className="col-sm-6">
                                        <h6 className="text-bold-700">{vehicle.tipo_grano}</h6>
                                    </div>
                                    <div className="col-sm-6 text-sm-right">
                                        <h6 className="text-bold-700">{`Calificación: ${vehicle.puntuacion_final}`}</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    {vehicle.analisis_vehiculo.map((_) => (
                                        <div className="col-sm-12" style={{cursor: 'pointer'}} onClick={() => {
                                            detail_vehicle(_.id)
                                        }}>
                                            <div
                                                className="alert mb-2"
                                                style={{background: vehicle.colorestado}}
                                                role="alert"
                                            >
                                                <div className="row">
                                                    <div className="col-6 text-white d-flex justify-content-start">
                                                        {_.analisis.nombre}
                                                    </div>
                                                    <div className="col-6 d-flex text-white justify-content-end">
                                                        {_.cantidad_detalle}/{_.analisis.intento_minimo}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="row">
                                    <div className="col-12 pl-5 pr-5 justify-content-end d-flex">
                                        <h6 className="text-bold-700">{`Valor total de compra: ${form.moneda} ${vehicle.precio_total_neto}`}</h6>
                                    </div>
                                    <div className="col-12 justify-content-end d-flex">
                                        <Link to={`/lote/detalle/${id_lote}`} className="btn btn-outline-dark m-2">
                                            Atrás
                                        </Link>
                                        {ButtonSend()}
                                    </div>
                                </div>
                                <Modal className="modal-main" show={showModal} style={{width: "35rem"}}
                                       onClick={() => setShowModal(!showModal)}>
                                    <div className="card">
                                        <div className="card-header ">
                                            <h3 className="d-flex justify-content-center">ATENCIÓN</h3><br/>
                                            <p>El total de la compra ha superado el peso fijado en el
                                                sistema.
                                                ¿Desea comprar todo el lote al precio fijado o comprar la
                                                diferencia al precio actual del grano?</p>
                                            {form.precio_fijado &&
                                                <p>Cantidad disponible: {form.precio_fijado.peso_disponible} {form.simbolo}</p>
                                            }
                                            <br/>
                                            <h4>Total actual: $ {totalActual.toFixed(2)}</h4><br/>
                                            <h4>Total a precio fijado: $ {totalFijado.toFixed(2)}</h4>
                                        </div>
                                        <div className="card-footer d-flex justify-content-md-center">
                                            <button type="button"
                                                    onClick={() => vehicleConfirm(0)}
                                                    className="btn  btn-outline-dark ">
                                                Todal actual
                                            </button>
                                            <button type="button"
                                                    onClick={() => vehicleConfirm(2)}
                                                    className="btn btn-success ml-md-3">
                                                Total fijado
                                            </button>
                                        </div>
                                    </div>
                                </Modal>
                                <Modal className="modal-main" show={modalDetail} style={{width: "60%"}}
                                       onClick={() => setModalDetail(!modalDetail)}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row mb-1">
                                                <div className="col-6 text-bold-600">
                                                    {analisis.analisis.nombre}
                                                </div>
                                                <div className="col-6 text-right text-bold-600">
                                                    {analisis.analisis.codigo}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    Puntuación inicial: {analisis.puntuacion_sistema}
                                                </div>
                                                <div className="col-4">
                                                    Ajuste: {analisis.ajuste_usuario}
                                                </div>
                                                <div className="col-4 text-right">
                                                    Puntuación final: {analisis.puntuacion_final}
                                                </div>
                                                {(analisis.detalles) &&
                                                <div className="table-responsive mt-2">
                                                    <DataTable
                                                        dataTable={analisis.detalles}
                                                        dataLimit={analisis.detalles.length}
                                                        columns={[
                                                            {
                                                                name: "valor_variable",
                                                                label: "Variable",
                                                                type: "text",
                                                            },
                                                            {
                                                                name: "puntaje",
                                                                label: "Puntaje",
                                                                type: "text",
                                                            },
                                                            {
                                                                name: "imagenes",
                                                                label: "Imagenes",
                                                                type: "avatar-array",
                                                            },
                                                        ]}
                                                        actions={null}
                                                    />
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default DetailVehicle;
