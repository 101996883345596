import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
    createProvider,
    CREATE_PROVIDER,
    clear_providers,
    getAllProviders
} from "../../../redux/provider";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/footer";

const CreateProvider = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const _create_provider = useSelector(
        (state) => state.provider_module._create_provider
    );
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({
        ruc: "",
        nombres: "",
        apellidos: "",
        direccion: "",
    });
    useEffect(() => {
        if (_create_provider.status === 201) {
            dispatch(clear_providers(CREATE_PROVIDER));
            history.push("/proveedores");
            dispatch(getAllProviders(token));
        } else if (_create_provider.status !== 0) {
            dispatch(clear_providers(CREATE_PROVIDER));
            toast.error(_create_provider.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_create_provider, dispatch, history, token]);

    useEffect(() => {
    }, []);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields
        if (form.ruc === "") {
            toast.error("Debe ingresar el ruc", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.nombres === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.apellidos === "") {
            toast.error("Debe ingresar el apellido", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.direccion === "") {
            toast.error("Debe ingresar la dirección", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        dispatch(createProvider(token, form));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Proveedores"
                            items={[
                                {label: "Lista de proveedores", link: "/proveedores"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear Proveedor</h4>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer /></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                name="ruc"
                                                label="RUC"
                                                value={form.ruc}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className=" col-md-6">
                                            <Input
                                                name="nombres"
                                                label="Nombres"
                                                value={form.nombres}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className=" col-md-6">
                                            <Input
                                                name="apellidos"
                                                label="Apellidos"
                                                value={form.apellidos}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className=" col-md-6">
                                            <Input
                                                name="direccion"
                                                label="Dirección"
                                                value={form.direccion}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/proveedores" className="btn btn-outline-dark m-1">
                                        Atrás
                                    </Link>
                                    <Button
                                        type="submit"
                                        text="Guardar"
                                        theme="primary"
                                        classes="m-1"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default CreateProvider;
