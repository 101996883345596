import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Footer from "../../../components/footer";

const PriceFixed = (props) => {
    // let history = useHistory();
    const prices_fixed = useSelector(
        (state) => state.prices_fixed_module._get_all_prices_fixed
    );
    const [data, setData] = useState([]);
    useEffect(() => {
        if (prices_fixed.data.datos) {
            setData(prices_fixed.data.datos);
        }
    }, [prices_fixed]);
    const [permisos, setPermisos] = useState([]);
    // const [actions, setActions] = useState([]);


    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Fijación"
                            items={[{label: "Fijación", link: "/fijacion"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_provider').length > 0 ? <Link
                                        to="/fijacion/crear"
                                        className="btn btn-primary"
                                    >
                                        Crear
                                    </Link> : null
                                }

                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: 'codigo',
                                                label: 'Código',
                                                type: 'text'
                                            },

                                            {
                                                name: 'precio_fijado',
                                                label: 'Precio Fijado',
                                                type: 'text'
                                            },
                                            {
                                                name: 'peso_fijado',
                                                label: 'Peso Fijado',
                                                type: 'text'
                                            },
                                            {
                                                name: 'peso_disponible',
                                                label: 'Peso Disponible',
                                                type: 'text',
                                            },
                                            {
                                                name: 'proveedor',
                                                label: 'Proveedor',
                                                type: 'object',
                                                field_show: 'ruc'
                                            }
                                        ]}
                                        actions={null}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default PriceFixed;
