import {
    URLAPI,
    BATCHES_PATH,
    BATCHE_CREATE_PATH,
    VEHICLE_CREATE_PATH,
    VEHICLE_PAY_PATH,
    VEHICLE_CONFIRM_PATH,
} from "../config/index";
import {LOGOUT} from './users'
import axios from "axios";

const init = {
    _get_all_batches: {
        data: [],

        status: 0,
        message: {},
    },

    _create_batches: {
        data: {},
        status: 0,
        message: {},
    },

    _create_vehicle: {
        data: {},
        status: 0,
        message: {},
    },

    _pay_vehicle: {
        data: {},
        status: 0,
        message: {},
    },

    _confirm_vehicle: {
        data: {},
        status: 0,
        message: {},
    },
};

const GET_ALL_BATCHES = "GET_ALL_BATCHES";
export const CREATE_BATCHES = "CREATE_BATCHES";
export const CREATE_VEHICLE = "CREATE_VEHICLE";
export const PAY_VEHICLE = "PAY_VEHICLE";
export const VEHICLE_CONFIRM = "VEHICLE_CONFIRM";

export const batches_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_BATCHES:
            return {
                ...state,
                _get_all_batches: action.payload,
            };
        case CREATE_BATCHES:
            return {
                ...state,
                _create_batches: action.payload,
            };
        case CREATE_VEHICLE:
            return {
                ...state,
                _create_vehicle: action.payload,
            };
        case PAY_VEHICLE:
            return {
                ...state,
                _pay_vehicle: action.payload,
            };
        case VEHICLE_CONFIRM:
            return {
                ...state,
                _confirm_vehicle: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
};

export const getAllBatches = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${BATCHES_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_BATCHES,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_ALL_BATCHES,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_BATCHES,
                payload: {
                    data: [],
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const createBatches = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${URLAPI}${BATCHE_CREATE_PATH}`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: CREATE_BATCHES,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_BATCHES,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_BATCHES,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const createVehicle = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${URLAPI}${VEHICLE_CREATE_PATH}`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: CREATE_VEHICLE,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_VEHICLE,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_VEHICLE,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const payVehicle = (token, data) => async (dispatch) => {
    try {
        const response = await axios.put(
            `${URLAPI}${VEHICLE_PAY_PATH}`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: PAY_VEHICLE,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: PAY_VEHICLE,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: PAY_VEHICLE,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const confirmVehicle = (token, data) => async (dispatch) => {
    try {
        const response = await axios.patch(
            `${URLAPI}${VEHICLE_CONFIRM_PATH}`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: VEHICLE_CONFIRM,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: VEHICLE_CONFIRM,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: VEHICLE_CONFIRM,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const clear_batches = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: [],
            status: 0,
            message: {},
        },
    });
};
