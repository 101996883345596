import {
  URLAPI,
  SETTING_PATH,
  SETTING_UPDATE_PATH,
} from "../config/index";
import axios from "axios";
import {LOGOUT} from "./users";

/** @module Redux/users */

/* Es el estado inicial del reductor. */
const init = {
  _get_all_setting: {
    data: [],
    status: 0,
    message: {},
  },
  _update_setting: {
    data: {},
    status: 0,
    message: {},
  },
};

const GET_ALL_SETTING = "GET_ALL_SETTING";
export const SETTING_UPDATE = "SETTING_UPDATE";

export const setting_module = (state = init, action) => {
  switch (action.type) {
    case GET_ALL_SETTING:
      return {
        ...state,
        _get_all_setting: action.payload,
      };
    case SETTING_UPDATE:
      return {
        ...state,
        _update_setting: action.payload,
      };
    case LOGOUT:
      return init;
    default:
      return state;
  }
};

export const getAllSetting = (token) => async (dispatch) => {
  try {
    const response = await axios.get(`${URLAPI}${SETTING_PATH}`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_SETTING,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ALL_SETTING,
        payload: {
          data: [],
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: GET_ALL_SETTING,
        payload: {
          data: [],
          status: 500,
          message: error.message,
        },
      });
    }
  }
};

export const updateSetting = (token, id, data) => async (dispatch) => {
  try {
    let form = new FormData();

    for (var key in data) {
      form.append(key, data[key]);
    }
    const response = await axios.patch(
        `${URLAPI}${SETTING_UPDATE_PATH}${id}/`,
        form,
        {
          headers: {
            Authorization: `Basic ${token}`,
          },
        }
    );
    dispatch({
      type: SETTING_UPDATE,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: SETTING_UPDATE,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: SETTING_UPDATE,
        payload: {
          data: {},
          status: 500,
          message: error.message,
        },
      });
    }
  }
};

export const clear_setting = (type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: {
      data: [],
      status: 0,
      message: {},
    },
  });
};
