import React, {useEffect, useState} from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import {useHistory} from "react-router-dom";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import route from "./routes";
import {useSelector, useDispatch} from "react-redux";
import {get_all_users, get_all_groups, get_all_permisions} from '../redux/users';
import {getAllProviders} from '../redux/provider';
import {getAllAnalysisList} from '../redux/analysis_list';
import {getAllBeansTypes} from '../redux/beants_types';
import {getAllBatches} from '../redux/batches';
import {getAllPricesFixed} from '../redux/prices_fixed';
import {getAllSetting} from '../redux/setting';
// import {SocketContext} from "../config/ws-client";
// import {TYPE_UPDATE_MESSAGE, TYPE_REPORT_GENERATE, TYPE_CREATE_MESSAGE} from '../config/ws-client'


const Index = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const _data_login = useSelector((state) => state.users_module.login);
    const _session_data = useSelector((state) => state.users_module._session_data);
    const [permisos, setPermissions] = useState([]);
    // const { socket } = useContext(SocketContext);
    // const _get_notification = useSelector((state) => state.visits_module._get_notification);
    const [token, setToken] = useState("")

    console.log(token);

    // const audio = new Audio("/base/assets/audio/audio.mp3");

    useEffect(() => {
        if (_data_login.status >= 200) {
            dispatch(getAllProviders(_data_login.data.token));
            dispatch(getAllBatches(_data_login.data.token));
            dispatch(getAllPricesFixed(_data_login.data.token));
            dispatch(getAllAnalysisList(_data_login.data.token));
            dispatch(getAllBeansTypes(_data_login.data.token));
            dispatch(get_all_users(_data_login.data.token));
            dispatch(get_all_groups(_data_login.data.token));
            dispatch(get_all_permisions(_data_login.data.token));
            dispatch(getAllSetting(_data_login.data.token));
            setToken(_data_login.data.token)
        } else {
            history.push("/");
        }

        if (JSON.stringify(_session_data.data) !== "{}") {
            if (_session_data.data.grupo_permiso) {
                if (_session_data.data.grupo_permiso.permisos) {
                    setPermissions(_session_data.data.grupo_permiso.permisos);
                }
            }
        }

    }, [dispatch, _data_login, _session_data, history]);


    // useEffect(() => {
    //   handleMessageWs()
    // }, [socket])

    // const sleep = (ms) => {
    //     return new Promise(resolve => setTimeout(resolve, ms));
    // }


    // const handleMessageWs = () => {
    //   if (socket) {
    //     socket.onmessage = (event) => {
    //       const data = JSON.parse(event.data);
    //       if (data.type == TYPE_UPDATE_MESSAGE) {
    //         visit_update_message(data, socket)
    //         return;
    //       }
    //
    //       if (data.type == TYPE_REPORT_GENERATE) {
    //         visit_report_generate(data, socket);
    //       }
    //
    //       if (data.type == TYPE_CREATE_MESSAGE) {
    //         visit_create_message(data, socket)
    //       }
    //
    //     }
    //   }
    // }


    // const visit_update_message = (data, socket) => {
    //   toast.success(data.message);
    //
    //   const notification = {
    //     title: data.title,
    //     message: data.message,
    //     result: null,
    //     id: data.id,
    //     read: false,
    //     type: TYPE_UPDATE_MESSAGE,
    //     show: true,
    //     date: data.date
    //   }
    //   let n = _get_notification.data ?? [];
    //   n.push(notification)
    //   // dispatch(save_notification(n));
    //   // dispatch(getAllVisits(token))
    //   // audio.play();
    // }
    //
    // const visit_create_message = (data, socket) => {
    //   dispatch(getAllVisits(token))
    // }
    //
    //
    // let timer = null;
    //
    // const visit_report_generate = (data, socket) => {
    //   console.log(data.id_task)
    //
    //
    //   timer = setInterval(() => {
    //     check_export_status(token, data.id_task).then(e => {
    //       let datos = e.data;
    //       console.log(datos)
    //       if (e.status == 200) {
    //         dispatch(clearVisit(GET_REPORT))
    //         const notification = {
    //           title: "Reporte Generado ",
    //           message: datos.result.nombre,
    //           result: datos.result,
    //           id: datos.id_task,
    //           read: false,
    //           date: datos.date,
    //           type: TYPE_REPORT_GENERATE,
    //           show: true
    //
    //         }
    //         let n = _get_notification.data ?? [];
    //         n.push(notification)
    //         dispatch(save_notification(n));
    //
    //         audio.play();
    //         clearInterval(timer)
    //         timer = null;
    //
    //       }
    //     }).catch(e => {
    //       clearInterval(timer)
    //       timer = null;
    //     })
    //   }, 5000)
    // }

    return (
        <Router>
            {/*<div className="header-navbar-shadow"/>*/}
            <Navbar/>
            <Sidebar/>
            <Switch>
                {route.map((item, index) => {
                    if (!item.meta.requiresAuth) {
                        return (
                            <Route path={item.path} exact={item.exact}>
                                {<item.component {...permisos} />}
                            </Route>
                        );
                    }

                    if (permisos.filter(x => item.meta.permission_required.includes(x.codigo)).length > 0) {
                        return (
                            <Route path={item.path} exact={item.exact}>
                                {<item.component {...permisos} />}
                            </Route>
                        );
                    }


                })}
            </Switch>
        </Router>
    );
};
export default Index;
