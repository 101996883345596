import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import Footer from "../../../components/footer";

const Provider = (props) => {
    let history = useHistory();
    const provider = useSelector(
        (state) => state.provider_module._get_all_providers
    );
    const [data, setData] = useState([]);
    useEffect(() => {
        if (provider.data.datos) {
            setData(provider.data.datos);
        }
    }, [provider]);
    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);


    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);


    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_provider').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        history.push(`/proveedores/editar/${item.id}`);
                    },
                })

            }
        }
        setActions(act);
    }, [permisos, history]);

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Proveedores"
                            items={[{label: "Proveedores", link: "/proveedores"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_provider').length > 0 ? <Link
                                        to="/proveedores/crear"
                                        className="btn btn-primary"
                                    >
                                        Crear
                                    </Link> : null
                                }

                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "ruc",
                                                label: "Ruc",
                                                type: "text",
                                            },
                                            {
                                                name: "nombres",
                                                label: "Nombres",
                                                type: "text",
                                            },
                                            {
                                                name: "apellidos",
                                                label: "Apellidos",
                                                type: "text",
                                            },
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Provider;
