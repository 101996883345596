import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import SelectInput from "../../../components/select";
import Button from "../../../components/button";
import {clear_batches, CREATE_BATCHES, createBatches, getAllBatches} from "../../../redux/batches";

const Index = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();

    const { token } = useSelector((state) => state.users_module.login.data) || null;

    const _createBatches = useSelector((state) => state.batches_module._create_batches);

    const _get_all_providers = useSelector(
        (state) => state.provider_module._get_all_providers
    );

    const _get_all_prices_fixed = useSelector(
        (state) => state.prices_fixed_module._get_all_prices_fixed
    );

    const batches_list = useSelector(
        (state) => state.batches_module._get_all_batches
    );

    const initForm = {
        id_proveedor: 0,
        id_precio_fijado: null,
    }

    const [form, setForm] = useState(initForm);

    const [data, setData] = useState([]);

    useEffect(() => {
        if (batches_list.data.datos) {
            setData(batches_list.data.datos);
        }
    }, [batches_list]);

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);


    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);


    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'view_batches').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        history.push(`/lote/detalle/${item.id}`);
                    },
                })

            }
        }
        setActions(act);
    }, [permisos, history]);

    const [showOverlay, setShowOverlay] = useState(false);
    const [showSibarRight, setShowSibarRight] = useState(false);

    useEffect(() => {
        if (_createBatches.status === 201) {
            setForm({
                id_proveedor: 0,
                id_precio_fijado: null,
            })
            setShowOverlay(false);
            setShowSibarRight(false);
            dispatch(clear_batches(CREATE_BATCHES));
            let id = _createBatches.data.datos[0].id
            history.push(`/lote/detalle/${id}`);
            dispatch(getAllBatches(token));
        } else if (_createBatches.status !== 0) {
            dispatch(clear_batches(CREATE_BATCHES));
            toast.error(_createBatches.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_createBatches, dispatch, history, token]);

    const [provider, setProvider] = useState([]);

    useEffect(() => {
        if (_get_all_providers.data) {
            setProvider(_get_all_providers.data.datos)
        }

    }, [_get_all_providers]);

    const [prices_fixed, setPricesfixed] = useState([]);

    const handleChange = (e) => {
        if (e.target.name === "id_proveedor" || e.target.name === "id_precio_fijado") {
            if (e.target.name === "id_proveedor") {
                let value_prices_fixed = []
                let item = _get_all_prices_fixed.data.datos.filter((x) => x.proveedor.id === parseInt(e.target.value));
                if (item.length > 0) {
                    value_prices_fixed.push({
                        "id": item[0].id,
                        "name": '[' + item[0].codigo + '] ' + item[0].precio_fijado.toString(),
                    })
                }
                setPricesfixed(value_prices_fixed);
            }
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields
        if (form.id_proveedor === 0) {
            toast.error("Debe seleccionar un proveedor", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        dispatch(createBatches(token, form));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Lista de lotes"
                            items={[{label: "Lista de lotes", link: "/lotes"}]}
                        />
                        {
                            permisos.filter(element => element.codigo === "add_batches").length > 0 ?
                                <button
                                    className="mt-2 btn btn-primary"
                                    onClick={() => {
                                        setShowSibarRight(!showSibarRight);
                                        setShowOverlay(!showSibarRight);
                                    }}
                                >Nuevo Lote</button>
                                : null
                        }
                    </div>
                </div>
                <div className="content-body">
                    <div><ToastContainer/></div>
                    <div className="content-area-wrapper" style={{margin: 0}}>
                        <div className={`sidebar-left ${showSibarRight ? 'show' : ''}`}>
                            <div className="sidebar">
                                {

                                    permisos.filter(element => element.codigo === "add_batches").length > 0 ?
                                        <div className={`compose-new-mail-sidebar ${showSibarRight ? 'show' : ''}`}
                                             style={{
                                                 overflowY: 'scroll',
                                             }}
                                        >
                                            <div className="card shadow-none quill-wrapper p-0">
                                                <div className="card-header">
                                                    <h3 className="card-title" id="visita">
                                                        Nuevo Lote
                                                    </h3>
                                                    <button type="button" className="close close-icon"
                                                            onClick={() => {
                                                                setForm(initForm);
                                                                setShowSibarRight(false);
                                                                setShowOverlay(false);
                                                            }}
                                                    >
                                                        <i className="bx bx-x"/>
                                                    </button>
                                                </div>
                                                <form onSubmit={handleSubmit} id="compose-form">
                                                    <div className="card-content">
                                                        <div className="card-body pt-0">
                                                            <div className="form-group">
                                                                <label>Proveedor</label>
                                                                <SelectInput
                                                                    label="provider"
                                                                    name="id_proveedor"
                                                                    value={form.id_proveedor}
                                                                    onChange={handleChange}
                                                                    options={provider ? provider.map((value) => ({
                                                                        name: '[' + value.ruc + '] ' +value.nombres + ' ' + value.apellidos,
                                                                        id: value.id,
                                                                    })) : []}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Precio fijado</label>
                                                                <SelectInput
                                                                    label="prices_fixed"
                                                                    name="id_precio_fijado"
                                                                    value={form.id_precio_fijado}
                                                                    onChange={handleChange}
                                                                    options={prices_fixed ? prices_fixed.map((value) => ({
                                                                        name: value.name,
                                                                        id: value.id,
                                                                    })) : []}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer d-flex justify-content-end pt-0">
                                                        <Button
                                                            text="Guardar"
                                                            theme="primary"
                                                            type="submit"
                                                        />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <div className={`app-content-overlay ${showOverlay ? 'show' : ''}`}
                             onClick={
                                 () => {
                                     setForm(initForm);
                                     setShowSibarRight(!showSibarRight);
                                     setShowOverlay(!showOverlay);
                                 }
                             }
                        />
                        <div className="card">
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <DataTable
                                            dataTable={data}
                                            columns={[
                                                {
                                                    name: "fecha_registro",
                                                    label: "Fecha",
                                                    type: "text",
                                                },
                                                {
                                                    name: "proveedor",
                                                    label: "Nombres",
                                                    type: "object",
                                                    field_show: 'nombres',
                                                },
                                                {
                                                    name: "proveedor",
                                                    label: "Apellidos",
                                                    type: "object",
                                                    field_show: 'apellidos',
                                                }
                                            ]}
                                            actions={actions}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
