import Users from "../pages/admin/users/index";
import CreateUser from "../pages/admin/users/create";
import UpdateUser from "../pages/admin/users/update";
import GroupList from '../pages/admin/groups/index';
import CreateGroup from '../pages/admin/groups/create';
import UpdateGroup from '../pages/admin/groups/update';
import Provider from "../pages/admin/provider/index";
import CreateProvider from "../pages/admin/provider/create";
import UpdateProvider from '../pages/admin/provider/update';
import AnalysisList from "../pages/admin/analysis/analysis_list";
import UpdateAnalysisList from '../pages/admin/analysis/update_analysis_list';
import CreateAnalysis from '../pages/admin/analysis/create_analysis';
import BatchesList from "../pages/admin/batches/index";
import CreateVehicle from '../pages/admin/batches/create_vehicle';
import DetailVehicle from '../pages/admin/batches/detail_vehicle';
import DetailBatches from '../pages/admin/batches/detail';
import PricesFixedList from "../pages/admin/price-fixed/index";
import CreatePriceFixed from '../pages/admin/price-fixed/create';
import SettingList from "../pages/admin/setting/index";
import SettingUpdate from "../pages/admin/setting/update";


const routes = [
    {
        path: "/lotes",
        name: "lotes",
        exact: true,
        icon: "bx bx-package",
        component: BatchesList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Lotes",
            permission_required: [
                "view_batches",
            ],
        },
    },
    {
        path: "/lote/detalle/:id",
        name: "lotes",
        exact: false,
        icon: "bx bx-package",
        component: DetailBatches,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Detalle de lote",
            permission_required: [
                "view_batches",
                // "view_vehicles",
                // "create_vehicles",
            ],
        },
    },
    {
        path: "/lote/:id/vehiculo/crear",
        name: "lotes",
        exact: false,
        icon: "bx bx-package",
        component: CreateVehicle,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Lote",
            permission_required: [
                "add_vehicles",
            ],
        },
    },
    {
        path: "/lote/:id_lote/vehiculo/:id_vehiculo/detalle",
        name: "lotes",
        exact: false,
        icon: "bx bx-package",
        component: DetailVehicle,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Lote",
            permission_required: [
                "view_vehicles",
                "change_vehicles",
            ],
        },
    },
    {
        path: "/proveedores",
        name: "proveedores",
        exact: true,
        icon: "bx bxs-truck",
        component: Provider,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Proveedores",
            permission_required: [
                "view_provider",
            ],
        },
    },
    {
        path: "/proveedores/crear",
        name: "proveedores",
        exact: false,
        icon: "bx bxs-truck",
        component: CreateProvider,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Proveedores",
            permission_required: [
                "add_provider",
            ],
        },
    },
    {
        path: "/proveedores/editar/:id",
        name: "proveedores",
        exact: true,
        icon: "bx bxs-truck",
        component: UpdateProvider,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Proveedores",
            permission_required: [
                "change_provider",
            ],
        },
    },
    {
        path: "/lista-analisis",
        name: "lista-analisis",
        exact: true,
        icon: "bx bx-search-alt",
        component: AnalysisList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Análisis",
            permission_required: [
                "view_analysislist",
            ],
        },
    },
    {
        path: "/lista-analisis/editar/:id",
        name: "lista-analisis",
        exact: true,
        icon: "bx bx-search-alt",
        component: UpdateAnalysisList,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Análisis",
            permission_required: [
                "change_analysislist",
            ],
        },
    },
    {
        path: "/analisis/crear/:id",
        name: "analisis",
        exact: true,
        icon: "bx bx-search-alt",
        component: CreateAnalysis,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Análisis",
            permission_required: [
                "add_analysis",
            ],
        },
    },
    {
        path: "/fijacion",
        name: "Fijación",
        exact: true,
        icon: "bx bx-dollar",
        component: PricesFixedList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Fijación",
            permission_required: [
                "view_pricesfixed",
            ],
        },
    },
    {
        path: "/fijacion/crear",
        name: "Fijación",
        exact: false,
        icon: "bx bx-dollar",
        component: CreatePriceFixed,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Crear fijación",
            permission_required: [
                "add_pricesfixed",
            ],
        },
    },
    {
        path: "/configuraciones",
        name: "Configuraciones",
        icon: "bx bx-cog",
        exact: true,
        component: SettingList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Configuraciones",
            permission_required: [
                "view_settings",
            ],
        },
    },
    {
        path: "/configuracion/editar/:id",
        name: "Configuraciones",
        icon: "bx bx-cog",
        exact: true,
        component: SettingUpdate,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Configuraciones",
            permission_required: [
                "change_settings",
            ],
        },
    },
    {
        path: "/usuarios",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: Users,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "view_user",
            ],
        },
    },
    {
        path: "/usuarios/crear",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: CreateUser,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "add_user",
            ],
        },
    },
    {
        path: "/usuarios/editar/:id",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: UpdateUser,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "change_user",
            ],
        },
    },
    {
        path: "/roles",
        name: "roles",
        icon: "bx bxs-user-pin",
        exact: true,
        component: GroupList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "view_group",
            ],
        },
    },
    {
        path: "/roles/crear",
        name: "roles",
        icon: "bx bxs-user",
        exact: true,
        component: CreateGroup,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "add_group",
            ],
        },
    },
    {
        path: "/roles/editar/:id",
        name: "roles",
        icon: "bx bxs-user",
        exact: true,
        component: UpdateGroup,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "change_group",
            ],
        },
    },
];

export default routes;
