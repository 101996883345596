import {
  URLAPI,
  PROVIDER_PATH,
  PROVIDER_CREATE_PATH,
  PROVIDER_UPDATE_PATH,
} from "../config/index";
import { LOGOUT } from './users'
import axios from "axios";

const init = {
  _get_all_providers: {
    data: [],

    status: 0,
    message: {},
  },

  _create_provider: {
    data: {},
    status: 0,
    message: {},
  },
  _update_provider: {
    data: {},
    status: 0,
    message: {},
  },
};

const GET_ALL_PROVIDERS = "GET_ALL_PROVIDERS";
export const CREATE_PROVIDER = "CREATE_PROVIDER";
export const UPDATE_PROVIDER = "UPDATE_PROVIDER";

export const provider_module = (state = init, action) => {
  switch (action.type) {
    case GET_ALL_PROVIDERS:
      return {
        ...state,
        _get_all_providers: action.payload,
      };
    case CREATE_PROVIDER:
      return {
        ...state,
        _create_provider: action.payload,
      };
    case UPDATE_PROVIDER:
      return {
        ...state,
        _update_provider: action.payload,
      };
    case LOGOUT:
      return init;
    default:
      return state;
  }
};

export const getAllProviders = (token) => async (dispatch) => {
  try {
    const response = await axios.get(`${URLAPI}${PROVIDER_PATH}`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_PROVIDERS,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ALL_PROVIDERS,
        payload: {
          data: [],
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: GET_ALL_PROVIDERS,
        payload: {
          data: [],
          status: 500,
          message: error.message,
        },
      });
    }
  }
};

export const createProvider = (token, data) => async (dispatch) => {
  try {
    let form = new FormData();

    for (var key in data) {
      form.append(key, data[key]);
    }
    const response = await axios.post(
      `${URLAPI}${PROVIDER_CREATE_PATH}`,
      form,
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      }
    );
    dispatch({
      type: CREATE_PROVIDER,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CREATE_PROVIDER,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: CREATE_PROVIDER,
        payload: {
          data: {},
          status: 500,
          message: error.message,
        },
      });
    }
  }
};

export const updateProvider = (token, id, data) => async (dispatch) => {
  try {
    let form = new FormData();

    for (var key in data) {
      form.append(key, data[key]);
    }
    const response = await axios.patch(
      `${URLAPI}${PROVIDER_UPDATE_PATH}${id}/`,
      form,
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      }
    );
    dispatch({
      type: UPDATE_PROVIDER,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: UPDATE_PROVIDER,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: UPDATE_PROVIDER,
        payload: {
          data: {},
          status: 500,
          message: error.message,
        },
      });
    }
  }
};

export const clear_providers = (type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: {
      data: [],
      status: 0,
      message: {},
    },
  });
};
