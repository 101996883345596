import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import SelectInput from "../../../components/select";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
    clear_user_module,
    CREATE_USER,
    create_user,
    get_all_users
} from "../../../redux/users";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/footer";

const CreateUser = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const _get_all_groups = useSelector(
        (state) => state.users_module._get_all_groups
    );

    const _create_user = useSelector((state) => state.users_module._create_user);
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({
        usuario: "",
        nombres: "",
        apellidos: "",
        correo: "",
        foto: "",
        id_grupo_permiso: "",
        url_confirmacion: window.location.origin + "/activar-cuenta",
    });
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        if (_create_user.status === 201) {
            dispatch(clear_user_module(CREATE_USER));
            history.push("/usuarios");
            dispatch(get_all_users(token));
        } else if (_create_user.status !== 0) {
            dispatch(clear_user_module(CREATE_USER));
            toast.error(_create_user.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_create_user, dispatch, history, token]);


    useEffect(() => {
        if (_get_all_groups.data) {
            setGroups(_get_all_groups.data.datos);
        }
    }, [_get_all_groups]);

    const handleChange = (e) => {
        if (e.target.name === "id_grupo_permiso") {
            //cast to int
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }

    };

    const handleFileChange = (e) => {
        setForm({
            ...form,
            foto: e.target.files[0],
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields

        if (form.usuario === "") {
            toast.error("Debe ingresar el usuario", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.nombres === "") {
            toast.error("Debe ingresar los nombres", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.apellidos === "") {
            toast.error("Debe ingresar los apellidos", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.correo === "") {
            toast.error("Debe ingresar el correo electrónico", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.id_grupo_permiso === "") {
            toast.error("Debe seleccionar un grupo de permiso", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        dispatch(create_user(form, token));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Usuarios"
                            items={[
                                {label: "Lista de usuarios", link: "/usuarios"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear Usuario</h4>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer/></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                label="usuario"
                                                name="usuario"
                                                value={form.usuario}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                label="nombres"
                                                name="nombres"
                                                value={form.nombres}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                label="apellidos"
                                                name="apellidos"
                                                value={form.apellidos}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                label="Correo"
                                                name="correo"
                                                value={form.correo}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Grupo de Permisos</label>
                                                <SelectInput
                                                    label="Rol"
                                                    name="id_grupo_permiso"
                                                    value={form.grupo_permiso}
                                                    onChange={handleChange}
                                                    options={groups ? groups.map((group) => ({
                                                        name: group.nombre,
                                                        id: group.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                name="foto"
                                                label="Foto"
                                                type="file"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/usuarios" className="btn btn-outline-dark m-1">
                                        Atrás
                                    </Link>
                                    <Button type="submit" text="Guardar" theme="primary"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default CreateUser;
