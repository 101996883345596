import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SelectInput from "../../../components/select";

import { useDispatch, useSelector } from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    clear_analysis_list,
    updateAnalysisList,
    getAllAnalysisList,
    UPDATE_ANALYSIS_LIST
} from "../../../redux/analysis_list";
import DataTable from "../../../components/data-table-simple";
import Modal from "../../../components/modal";
import Footer from "../../../components/footer";

const UpdateAnalysisList = (props) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const analysis_list = useSelector(
        (state) => state.analysis_list_module._get_all_analysis_list
    );

    const _get_all_beans_types = useSelector(
        (state) => state.beans_types_module._get_all_beans_types
    );

    const _update_analysis_list = useSelector((state) => state.analysis_list_module._update_analysis_list);
    const { token } = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({
        nombre: "",
        codigo: "",
        id_tipo_grano: 0,
        tipo_grano: "",
    });

    const [analisis, setAnalisis] = useState([])

    useEffect(() => {
        let item = analysis_list.data.datos.filter((x) => x.id === parseInt(id))
        if (item.length > 0) {
            setForm({
                nombre: item[0].nombre,
                codigo: item[0].codigo,
                tipo_grano: item[0].tipo_grano.nombre,
            });
            setAnalisis(item[0].analisis)
        }
    }, [analysis_list, id]);


    const [beans_types, setBeansTypes] = useState([]);

    // const [optionsBeansTypes, setOptionsBeansTypes] = useState([]);
    useEffect(() => {
        if (_update_analysis_list.status === 200) {
            dispatch(clear_analysis_list(UPDATE_ANALYSIS_LIST));
            history.push("/lista-analisis");
            dispatch(getAllAnalysisList(token));
        } else if (_update_analysis_list.status !== 0) {
            dispatch(clear_analysis_list(UPDATE_ANALYSIS_LIST));
            toast.error(_update_analysis_list.message, {position: toast.POSITION.TOP_RIGHT});

        }
    }, [_update_analysis_list, dispatch, history, token]);

    useEffect(() => {
        if (_get_all_beans_types.data) {
            setBeansTypes(_get_all_beans_types.data.datos)
            // let options = _get_all_beans_types.data.datos.map((item) => {
            //     return {
            //         value: item.id,
            //         label: item.nombre,
            //     };
            // });
            // setOptionsBeansTypes(options);
        }

    }, [_get_all_beans_types]);

    const handleChange = (e) => {
        if (e.target.name === "id_tipo_grano") {
            //cast to int
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.codigo === "") {
            toast.error("Debe ingresar el código", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.id_tipo_grano === 0) {
            toast.error("Debe seleccionar el tipo de grano", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        dispatch(updateAnalysisList(token, id, form));
    };

    const [modalAnalysis, setModalAnalysis] = useState(false);

    const [detailAnalisis, setDetailAnalisis] = useState({
        id: 0,
        codigo: "",
        nombre: "",
        nombre_variable: "",
        tipo_variable: "",
        intento_minimo: 0,
        reglas: []
    });

    function view_analys(id) {
        console.log(analisis)
        if (analisis.length > 0) {
            let value = analisis.filter((x) => x.id === parseInt(id))
            if (value.length > 0) {
                setDetailAnalisis(value[0])
                setModalAnalysis(true);
            }
        }
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Lista de análisis"
                            items={[
                                { label: "Lista de análisis", link: "/lista-analisis" },
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Editar lista de análisis</h4>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer/></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                label="nombre"
                                                name="nombre"
                                                value={form.nombre}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                label="codigo"
                                                name="codigo"
                                                value={form.codigo}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Tipo de grano</label>
                                                <SelectInput
                                                    label="tipo_grano"
                                                    name="id_tipo_grano"
                                                    select={form.tipo_grano}
                                                    value={form.id_tipo_grano}
                                                    onChange={handleChange}
                                                    options={beans_types ? beans_types.map((value) => ({
                                                        name: value.nombre,
                                                        id: value.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <h5 className="text-bold-700">Análisis a realizar</h5>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end">
                                            <Link to={`/analisis/crear/${id}`}
                                                  className="btn btn-lg btn-primary">
                                                Agregar
                                            </Link>
                                        </div>

                                    </div>
                                    <div className="row mt-4">
                                        {
                                            analisis.map((_) => (
                                                <div className="col-12"  style={{cursor: 'pointer'}}
                                                     onClick={() => {view_analys(_.id)}}>
                                                    <div className="alert alert-secondary mb-2" role="alert">
                                                        <div className="row">
                                                            <div className="col-6  d-flex justify-content-start">
                                                                {_.nombre}
                                                            </div>
                                                            <div className="col-6 d-flex justify-content-end">
                                                                {_.nombre_variable}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <Modal className="modal-main" show={modalAnalysis} style={{width: "60%"}}
                                           onClick={() => setModalAnalysis(!modalAnalysis)}>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row mb-1">
                                                    <div className="col-6 text-bold-600">
                                                        {detailAnalisis.nombre}
                                                    </div>
                                                    <div className="col-6 text-right text-bold-600">
                                                        {detailAnalisis.codigo}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-4">
                                                        Variable: {detailAnalisis.nombre_variable}
                                                    </div>
                                                    <div className="col-4 text-right">
                                                        Tipo: {detailAnalisis.tipo_variable}
                                                    </div>
                                                    <div className="col-4 text-right">
                                                        Número de intentos: {detailAnalisis.intento_minimo}
                                                    </div>
                                                    {(detailAnalisis.reglas) &&
                                                    <div className="table-responsive mt-2">
                                                        <h4>Reglas</h4>
                                                        <DataTable
                                                            dataTable={detailAnalisis.reglas}
                                                            dataLimit={detailAnalisis.reglas.length}
                                                            columns={[
                                                                {
                                                                    name: "limite_inferior",
                                                                    label: "Límite inferior",
                                                                    type: "text",
                                                                },
                                                                {
                                                                    name: "limite_superior",
                                                                    label: "Límite superior",
                                                                    type: "text",
                                                                },
                                                                {
                                                                    name: "puntos",
                                                                    label: "puntos",
                                                                    type: "text",
                                                                },
                                                            ]}
                                                            actions={null}
                                                        />
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/lista-analisis" className="btn btn-outline-dark m-1">
                                        Atrás
                                    </Link>
                                    <Button type="submit" text="Guaradar" theme="primary" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default UpdateAnalysisList;
