import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import { useSelector } from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import CardItem from "../../../components/card-item";
import Footer from "../../../components/footer";

const DetailBatches = (props) => {
    let history = useHistory();
    const {id} = useParams();

    const _get_all_batches = useSelector(
        (state) => state.batches_module._get_all_batches
    );

    const [form, setForm] = useState({
        id: 0,
        cantidad_vehiculo: 0,
        fecha_registro: "",
        precio_fijado: {
            id: 0,
            codigo: "",
            peso_disponible: "",
            peso_fijado: 0,
            precio_fijado: ""
        },
        proveedor: {
            id: 0,
            ruc: 0,
            nombres: "",
            apellidos: "",
        },
        total_peso_bruto: 0,
        total_peso_neto: 0,
        total_peso_tara: 0,
        usuario_analiza: "",
        usuario_registra: "",
        vehiculos: [],
        moneda: "",
        simbolo: ""
    });

    useEffect(() => {
        if (_get_all_batches.data) {
            let value = _get_all_batches.data.datos.filter((x) => x.id === parseInt(id))
            if (value.length > 0) {
                setForm(value[0]);
            }
        }
    }, [_get_all_batches, id]);

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Lotes"
                            items={[
                                { label: "Lista de lotes", link: "/lotes" },
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-9 mb-2 mt-2">
                                        <h6 className='text-bold-500'>
                                            {form.proveedor.nombres + " " + form.proveedor.apellidos}
                                        </h6>
                                    </div>
                                    <div className="col-md-3 mb-2 mt-2">
                                        <h6>Vehículos {form.cantidad_vehiculo}</h6>
                                    </div>
                                    <div className="col-12 row">
                                        <div className="col-4 mb-2">
                                            {`Peso bruto: ${form.total_peso_bruto} ${form.simbolo}`}
                                        </div>
                                        <div className="col-4 mb-2">
                                            {`Peso tara: ${form.total_peso_tara} ${form.simbolo}`}
                                        </div>
                                        <div className="col-4 mb-2">
                                            {`Peso neto: ${form.total_peso_neto} ${form.simbolo}`}
                                        </div>
                                        <div className="col-4 mb-2">
                                            {`Precio quintal:  ${form.moneda} ${form.precio_fijado ? form.precio_fijado.precio_fijado : form.precio_quintal}`}
                                        </div>
                                        <div className="col-4 mb-2">
                                            {`Peso fijado: ${form.precio_fijado ? form.precio_fijado.peso_fijado : 0} ${form.simbolo}`}
                                        </div>
                                        <div className="col-12">
                                            <h6>Lista de vehículos</h6>
                                        </div>
                                        <div className="col-12 d-flex justify-content-end mb-2">
                                            <button type="button"
                                                    className="btn btn-primary text-bold "
                                                    onClick={() => {
                                                        history.push(`/lote/${id}/vehiculo/crear`)
                                                    }}
                                            >
                                                Agregar
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row col-12">
                                        {
                                            form.vehiculos.map((_, i) => (
                                                <div className="col-xl-3 col-md-6 col-xs-12 col-sm-6" key={i}>
                                                    <CardItem
                                                        title={_.placa}
                                                        footer={_.estado}
                                                        color={_.colorestado}
                                                        onClick={() => {
                                                            history.push(`/lote/${id}/vehiculo/${_.id}/detalle`)
                                                        }}
                                                        style={{cursor: 'pointer'}}
                                                    >
                                                        <>
                                                            <h6 className='text-white'>Tipo grano {_.tipo_grano}</h6><br/>
                                                            <h6 className='text-white'>Peso bruto: {_.peso_bruto} {form.simbolo}</h6>
                                                        </>
                                                    </CardItem>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 justify-content-end d-flex">
                                        <Link to={`/lotes`} className="btn btn-outline-dark m-2">
                                            Atrás
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default DetailBatches;
