import {
  URLAPI,
  ANALYSIS_CREATE_PATH,
} from "../config/index";
import { LOGOUT } from './users'
import axios from "axios";

const init = {

  _create_analysis: {
    data: {},
    status: 0,
    message: {},
  },
};

export const CREATE_ANALYSIS = "CREATE_ANALYSIS";

export const analysis_module = (state = init, action) => {
  switch (action.type) {
    case CREATE_ANALYSIS:
      return {
        ...state,
        _create_analysis: action.payload,
      };
    case LOGOUT:
      return init;
    default:
      return state;
  }
};

//create analisis
export const createAnalysis = (token, data) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${URLAPI}${ANALYSIS_CREATE_PATH}`,
      data,
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      }
    );
    dispatch({
      type: CREATE_ANALYSIS,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CREATE_ANALYSIS,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: CREATE_ANALYSIS,
        payload: {
          data: {},
          status: 500,
          message: error.message,
        },
      });
    }
  }
};


export const clear_analysis = (type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: {
      data: [],
      status: 0,
      message: {},
    },
  });
};
