import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import SelectInput from "../../../components/select";
import { useDispatch, useSelector } from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    clear_batches,
    createVehicle,
    getAllBatches,
    CREATE_VEHICLE,
} from "../../../redux/batches";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Input from "../../../components/input";
import CheckBox from "../../../components/check-box";
import Footer from "../../../components/footer";

const VeiclesCreate = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams();

    const { token } = useSelector((state) => state.users_module.login.data) || null;

    const _createVehicle = useSelector((state) => state.batches_module._create_vehicle);

    const _get_all_beans_types = useSelector(
        (state) => state.beans_types_module._get_all_beans_types
    );

    const [form, setForm] = useState({
        id_lote: id,
        id_tipo_grano: 0,
        peso_entrada: 0,
        placa: '',
        romaneo: false,
    });

    useEffect(() => {
        if (_createVehicle.status === 201) {
            dispatch(clear_batches(CREATE_VEHICLE));
            history.push(`/lote/detalle/${id}`);
            dispatch(getAllBatches(token));
        } else if (_createVehicle.status !== 0) {
            dispatch(clear_batches(CREATE_VEHICLE));
            toast.error(_createVehicle.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_createVehicle, dispatch, history, token, id]);

    const [beans_types, setBeansTypes] = useState([]);

    useEffect(() => {
        if (_get_all_beans_types.data) {
            setBeansTypes(_get_all_beans_types.data.datos)
        }

    }, [_get_all_beans_types]);


    const handleChange = (e) => {
        if(e.target.name === "romaneo"){
            setForm({
                ...form,
                [e.target.name]: e.target.checked,
            });
        } else if (e.target.name === "id_tipo_grano") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields
        if (form.placa === "") {
            toast.error("Debe ingresar la placa del vehículo", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.id_tipo_grano === 0) {
            toast.error("Debe seleccionar el tipo de grano", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.peso_entrada === 0) {
            toast.error("Debe ingresar el peso de entrada", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        dispatch(createVehicle(token, form));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Vehículo"
                            items={[
                                { label: "Lotes", link: "/lotes"},
                                { label: "Detalle de lote", link: `/lote/detalle/${id}`},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear vehículo</h4>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer /></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                label="Placa"
                                                name="placa"
                                                value={form.placa}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Tipo de grano</label>
                                                <SelectInput
                                                    label="beans_types"
                                                    name="id_tipo_grano"
                                                    value={form.id_tipo_grano}
                                                    onChange={handleChange}
                                                    options={beans_types ? beans_types.map((value) => ({
                                                        name: value.nombre,
                                                        id: value.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                label="Peso entrada"
                                                name="peso_entrada"
                                                type="number"
                                                value={form.peso_entrada}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                            <CheckBox
                                                label="Romaneo"
                                                name="romaneo"
                                                id="romaneo"
                                                onChange={handleChange}
                                                state={form.romaneo}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to={`/lote/detalle/${id}`} className="btn btn-outline-dark m-1">
                                        Atrás
                                    </Link>
                                    <Button type="submit" text="Guardar" theme="primary" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default VeiclesCreate;
