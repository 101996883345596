import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Footer from "../../../components/footer";

const Setting = (props) => {
    let history = useHistory();
    const get_all_setting = useSelector(
        (state) => state.setting_module._get_all_setting
    );
    const [data, setData] = useState([]);

    useEffect(() => {
        console.log(get_all_setting.data)
        if (get_all_setting.data.datos) {
            setData(get_all_setting.data.datos);
        }
    }, [get_all_setting]);

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);


    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);


    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_settings').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        history.push(`/configuracion/editar/${item.id}`);
                    },
                })

            }
        }
        setActions(act);
    }, [permisos, history]);

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Configuración"
                            items={[{label: "Configuraciones", link: "/configuraciones"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "precio_quintal",
                                                label: "Precio quintal",
                                                type: "text",
                                            },
                                            {
                                                name: "limite_ajuste_inferior",
                                                label: "Límite inferior",
                                                type: "text",
                                            },
                                            {
                                                name: "limite_ajuste_superior",
                                                label: "Límite superior",
                                                type: "text",
                                            },
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Setting;
