import React from 'react';
import './modal.css';


const Modal = ({ show= false, children, className, style, onClick }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
      <div className={showHideClassName} onClick={onClick}>
        <section className={className} style={style}>
          {children}
        </section>
      </div>
    );
}

export default Modal;
