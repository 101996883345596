export const URLAPI = "https://pruebas.back.maicao.innode.pro";


export const WEBSOCKET = 'wss://pruebas.back.maicao.innode.pro';


export const PROVIDER_PATH = "/provider/list/";
export const PROVIDER_CREATE_PATH = "/provider/create/";
export const PROVIDER_UPDATE_PATH = "/provider/update/";


export const ANALYSIS_LIST_PATH = "/analysis/list/";
export const ANALYSIS_LIST_CREATE_PATH = "/analysis/list/create/";
export const ANALYSIS_LIST_UPDATE_PATH = "/analysis/list/update/";

export const ANALYSIS_CREATE_PATH = "/analysis/create/";

export const BEANS_TYPES_PATH = "/beans_types/list/";
export const BEANS_TYPES_CREATE_PATH = "/beans_types/create/";
export const BEANS_TYPES_UPDATE_PATH = "/beans_types/update/";

export const BATCHES_PATH = "/batches/list/";
export const BATCHE_CREATE_PATH = "/batches/create/";
export const VEHICLE_CREATE_PATH = "/vehicles/create/";
export const VEHICLE_PAY_PATH = "/vehicles/pay-vehicle/";
export const VEHICLE_CONFIRM_PATH = "/vehicles/confirm/";

export const PRICES_FIXED_PATH = "/prices-fixed/list/";
export const PRICE_FIXED_CREATE_PATH = "/prices-fixed/create/";

export const USERS_PATH = "/user/list/";
export const TOKEN_PATH = "/user/token/";
export const USERS_UPDATE_PATH = "/user/update/";
export const USERS_CREATE_PATH = "/user/create/";
export const USER_GROUPS_PATH = "/user/group-list/";
export const USER_GET_PATH = "/user/get/";
export const USER_GET_ALL_PERMISSIONS_PATH = '/user/permission-list/';
export const USER_CREATE_GROUP_PATH = '/user/group-create/';
export const USER_UPDATE_GROUP_PATH = '/user/group-update/';
export const USER_ACTIVATE_PATH = '/user/activate/';
export const USER_LOGOUT_PATH = '/user/logout/';
export const USER_PASSWORD_RESET_PATH = '/user/password/reset/';
export const USER_PASSWORD_RESET_CONFIRM_PATH = '/user/password/reset/confirm/';

export const SETTING_PATH = "/setting/list/";
export const SETTING_UPDATE_PATH = "/setting/update/";

const BASE_CREADO = "CREADO";
const BASE_EN_ESPERA = "EN ESPERA";
const BASE_APROBADO = "APROBADO";
const BASE_ANULADO = "ANULADO";
const BASE_CADUCADO = "CADUCADO";

export const get_status = (status)  => {
    switch (status) {
        case BASE_CREADO:
            return 'primary';
        case BASE_EN_ESPERA:
            return 'warning';
        case BASE_APROBADO:
            return 'success';
        case BASE_ANULADO:
            return 'danger';
        case BASE_CADUCADO:
            return 'dark';
        default:
            return 'primary';
    }
}

export const VIGENTE = "VIGENTE";
export const AGOTADO = "AGOTADO";
export const CADUCADO = "CADUCADO";
export const INGRESADO = "INGRESADO";
export const EN_PROCESO ="EN PROCESO"
export const PENDIENTE_DE_PAGO = "PENDIENTE DE PAGO"
export const RECHAZADO = "RECHAZADO";
export const PAGADO = "PAGADO";


export const APP_NAME = "Sistema maicao";


