import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { loadState } from "./state";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { users_module } from "./users";
import { provider_module } from "./provider";
import { analysis_list_module } from "./analysis_list";
import { analysis_module } from "./analysis";
import { beans_types_module } from "./beants_types";
import { batches_module } from "./batches";
import { prices_fixed_module } from "./prices_fixed";
import { setting_module } from "./setting";

const rootReducer = combineReducers({
  users_module: users_module,
  batches_module: batches_module,
  prices_fixed_module: prices_fixed_module,
  provider_module: provider_module,
  analysis_list_module: analysis_list_module,
  analysis_module: analysis_module,
  beans_types_module: beans_types_module,
  setting_module: setting_module,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const generateStore = () => {
  const initialData = loadState();

  const store = createStore(
    persistedReducer,
    initialData,
    composeEnhancers(applyMiddleware(thunk))
  );

  const persistor = persistStore(store, ["auth"]);

  return { store, persistor };
};
