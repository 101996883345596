import React from "react";

/** @module Components/Input */


/**
 * @component
 * Es una función que toma un montón de accesorios y devuelve un div con una etiqueta y una entrada.
 * @param {string} type - El tipo de entrada.
 * @param {string} name - El nombre del campo.
 * @param {string} id - La identificación del campo.
 * @param {string} value - El valor del campo.
 * @param {function} onChange - La función que se ejecuta al cambiar el valor del campo.
 * @param {string} placeholder - El texto que se mostrará en el campo.
 * @param {string} label - El texto que se mostrará en el campo.
 * @param {boolean} disabled - Si el campo está deshabilitado.
 * @param {boolean} required - Si el campo es requerido.
 * @returns Un componente
 * @example
 * <Input
 *      type="text"
 *      name="nombre"
 *      id="identificacion"
 *      value="valor"
 *      onChange={()=>{}}
 *      placeholder="Texto del campo"
 *      label="Texto del campo"
 *      disabled={false}
 *      required={false}
 *      />
 */

const Input = ({
  type,
  name,
  id,
  value,
  onChange,
  placeholder,
  label,
  disabled=false,
    required=false,   
    ...props
}) => {
  return (
    <div className="form-group">
      <label for={id}>{label}</label>
      <input
        type={type}
        name={name}
        id={id}
        className="form-control"
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
        required={required}
        {...props}
      />
    </div>
  );
};

export default Input;
